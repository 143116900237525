$(document).ready(function(){
  $('.carousel').slick({
    dots: true,
    slidesToShow: 4,
    autoplay: true,
    centerMode: true,
    variableWidth: true,
    speed: 1000
  });
});
